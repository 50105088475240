// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer-home {
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #424242;
}

.nav-text {
  position: relative;
  p {
    color: #BDBDBD;
    font-size: 0.9em;
  }
}

.footer-icons {
  margin-top: 22px;
  .icon-map-pin {
    position: relative;
    color: #BDBDBD;
    font-size: 0.9em;
    margin: 0 0.5em 0 0;
    top: -0.1rem;
  }

  .icon-phone {
    position: relative;
    color: #BDBDBD;
    font-size: 0.9em;
    margin: 0 0.5em 0 3.5em;
    top: -0.1rem;
  }

  .icon-mail {
    position: relative;
    color: #BDBDBD;
    font-size: 0.9em;
    margin: 0 0.5em 0 3.5em;
    top: -0.1rem;
  }
}

@media (max-width: 768px) {
  .footer-home {
    height: auto;
  }
}
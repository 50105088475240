body{
  width: 100%;
  overflow-x: hidden;
}
.success-title {
  color: #16a085;
}
.dis-none{
  display: none !important;
}

.form-hr{
  border-top: 1px dashed #7f8c8d;
}
.recent-grn-list{
  list-style-type: none;
  padding-left: 15px;

  li{
    border-bottom: 1px dashed #999999;
    font-size: .9em;
  }
  li:first-child{
    border-bottom: 1px solid #999999;
    font-weight: bold;
    font-size: 1em;
  }
}
.dataTables_wrapper{
  .dataTable{
    th{
      color: #51cbce;
    }
  }
  .dataTables_filter{
    float: right;
  }
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
  padding: 8px;
  font-size: .9em;
}
.badge-large{
  font-size: 14px;
}
.hub-datetime{
  margin: 0 7px;
  font-size: 1.2em;
  font-weight: 500;
  color: #2c3e50 !important;
  input{
    color: #2c3e50;
  }

  .react-datetime-picker__wrapper{
    //border: none;
    border-radius: 5px;
    //background-color: #f1f1f1;
    //box-shadow: 2px 2px 2px 1px #5e5e5e;
  }
}
.invoice-show-summary{
  .text-danger-im p{
    color: #af0606;
    font-weight: bold;
  }
}




//large devices only
@media (min-width: 768px) {
.invoice-show-summary{
  //position: fixed;
  //left: 65%;
  //right: 0;
}
}

.add-specs-label {
  font-size: 0.8em !important;
  text-decoration: underline;
  cursor: pointer;
  color: #959595;
}

.add-specs-select-main-specs {
  color: #616161 !important;
  font-size: 0.8571em !important;

  .form-control {
    //font-size: 0.8571em !important;
    color: #616161;
  }

  .css-1uccc91-singleValue{
    color: #616161 !important;
    font-size: 0.8571em !important;
    padding-bottom:5px
  }
}

.add-specs-select-sub-specs {

  .form-control {
    font-size: 0.8571em !important;
  }

  .add-specs-select-sub-specs-select {
    color: #616161 !important;
    font-size: 0.8575em !important;
  }

  .add-specs-select-sub-specs-input {
    color: #616161 !important;
    //font-size: 0.8571em !important;
  }

  .css-1uccc91-singleValue{
    color: #616161 !important;
    font-size: 0.8571em !important;
    padding-bottom:5px;
  }
}

.delete-spec{
  i{
    left: -16% !important;
    position: inherit !important;
    font-size: 0.8em !important;
    top: -4px !important;
    cursor: pointer !important;
  }
}

.add-spec{
  i{
    color:#007bff !important;
    position: inherit !important;
    font-size: 1.5em !important;
    //bottom: 26%!important;
    cursor: pointer !important;
  }
}

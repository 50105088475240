.header-logo{
  padding: 10px 0 !important;
  .b-brand{
    width: 160px;
  }
}
.m-header{
  .b-brand{
    width: 110px;
  }
}


.create-tour {
  font-weight: bold;
  font-size: 1em;
  //margin-bottom: 1.5em;
}

//$box-top-background: #00cec9;
$box-top-background: #018574;
//$box-top-background: #38ada9;
$recent-tour-radius: 3px;
$theme-background: #018574;

.text-max-width {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.recent-tour {
  border-radius: $recent-tour-radius;
  box-shadow: 0 2px 1px 0 #dddddd;
  max-width: 260px;
  cursor: pointer;

  .tour-description {
    border-top-left-radius: $recent-tour-radius;

    padding: 15px;
    min-height: 120px;
    background-color: $box-top-background;

    .tour-icon {
      font-size: 2.8em;
      color: #f2f2f2;
    }

    .title {
      font-weight: 600;
      color: #ffffff;
      margin-top: .5em;
      //font-family: Montserrat;
      margin-bottom: 0;
      cursor: pointer;
    }

    .tags {
      margin-bottom: 0;
      color: #f1f1f1;
      font-size: .8em;
    }
  }

  .bookmark-container {
    background-color: $box-top-background;
    border-top-right-radius: $recent-tour-radius;

    .bookmark {
      width: 30px;
      height: 20px;
      cursor: pointer;
    }

    .icon-bookmark {
      font-size: 1.5em;
      //color: #ffa801;
      color: #F2C558;
      margin-right: 5px;
      display: block;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .controls {
    padding: 5px 15px;
    background-color: #ffffff;
    border-bottom-left-radius: $recent-tour-radius;
    border-bottom-right-radius: $recent-tour-radius;

    .edit-label {
      //font-family: Montserrat;
      margin-bottom: 0;
      color: #0984e3;
      cursor: pointer;
      font-size: .9em;
      text-decoration: underline;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.input-with-icon {
  .icon {
    background-color: #ffffff;
    border: 1px solid #bdc3c7;
    border-right: none;
    padding-right: 5px;
    color: #95a5a6;
  }

  &:active {
    .icon {
      color: #2980b9;
    }
  }

  .input {
    //border: 1px solid #ced4da;
    border: 1px solid #bdc3c7;
    background-color: #ffffff;
    border-left: none;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    box-shadow: none;
  }

  .form-control-sm {
    padding: 16px 20px;
  }

  & .radius {
    border-radius: 25px;
  }

}

.into-label {
  font-size: .8rem;
  color: #34495e;
  font-weight: bold;
}

.view-type {
  padding-top: 10px;

  i {
    font-size: 1.2rem;
    margin-left: .5rem;
    cursor: pointer;
  }

  .separator {
    display: inline;
    border-right: 1px solid #95a5a6;
    top: -2px;
    position: relative;
    margin-left: .5rem;
  }
}

.custom-table {
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 15px;
  //font-family: $font-family-Montserrat;
  border: none;
  background-color: #fefefe;

  td {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #f1f1f1 !important;
    min-height: 30px;
    padding: 10px 10px !important;
    vertical-align: middle !important;
    //border-bottom: 1px solid #dddddd;
    white-space: normal;
    color: #7A7574;
  }

  tbody tr {

  }

  tbody tr:last-child td {
    border-bottom: 2px solid #dddddd !important;
  }

  thead tr:first-child {
    border: none;
    color: #7f8c8d;

    th {
      padding: 8px;
      border: none;
      border-bottom: 2px solid #dddddd;
    }

    th:first-child {
      width: 60px;
    }

    th:last-child {
      width: 100px;
    }
  }

  .editor-options {
    float: none;
  }
  .small-text {
    font-size: .9em;
  }
}

.react-bootstrap-table table {
  table-layout: auto;
}

.table {
  &.tours-table {
    th:nth-child(3) {
      //text-align: center;
      width: 150px;
    }

    th:nth-child(4) {
      text-align: center;
      width: 200px;
    }

    th:nth-child(5) {
      width: 200px;
    }

    th:nth-child(5), th:nth-child(6) {
      text-align: center;
      width: 160px;
    }

    td:last-child {
      font-size: 1.5em;
      color: #0a3d62;
    }

    th:nth-child(7) {
      text-align: center;
      width: 160px;
    }

    th:nth-child(8), th:nth-child(9) {
      width: 30px !important;
    }
  }

  .column-colored {
    //color: #487eb0 !important;
    color: #2D7D9A !important;
    font-weight: 600;
  }

  &.invoice-table {
    th:nth-child(3) {
      text-align: left;
      //width: 150px;
    }

    th:nth-child(4) {
      text-align: left;
      //width: 200px;
    }

    th:nth-child(5) {
      text-align: left;
      //width: 200px;
    }

    th:nth-child(6) {
      text-align: center;
      //width: 160px;
    }

    th:nth-child(7) {
      text-align: right;
      //width: 160px;
    }

    td:last-child {
      text-align: center;
      width: 30px;
    }

    //th:nth-child(8), th:nth-child(9) {
    //  width: 30px !important;
    //}
  }
}

.table-options{
  .show > button {
    background-color: #2d434e !important;
    border-radius: 3px;
    i {
      color: #f1f1f1;
    }
  }

  .card-option{
    font-size: .7em;
    padding: 2px 0;
    background-color: #ecf0f1;
    box-shadow: 1px 2px 1px 1px #BDBDBD;
    li > a{
      color: #2d434e;
    }
  }

  .card-option:before{
    color: #ecf0f1;
    text-shadow: none;
  }
  .dropdown--table-options{
    //i{
    //  color: #2d434e;
    //}
  }
}
.react-bs-table-sizePerPage-dropdown{
  button {
    padding: 5px 15px;
  }
  .dropdown-menu{
    min-width: 1rem;
    padding: 5px 0;
    a {
      font-weight: 600;
    }
  }
}
.react-bootstrap-table-pagination-total{
  font-size: .9em;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.pagination{
  justify-content: flex-end;
  .page-link{
    color: #0a3d62;
  }
  .page-item.active .page-link {
    color: #fff;
    background-color: #60a3bc;
    border-color: #60a3bc;
  }
}

.loading-label{
  margin-bottom: 0;
}

.fullscreen-enabled{
  background: $theme-background;
}

.tb-edit-icon {
  .icon-edit {
    font-size: 1rem;
    color: #FBC02D;
    cursor: pointer;
  }
}

.tb-trash-icon {
  .icon-trash {
    font-size: 1rem;
    color: #e53935;
    cursor: pointer;
  }
}

.tb-view-icon {
  .icon-eye {
    font-size: 1rem;
    color: #FF6F00;
    cursor: pointer;
  }
}
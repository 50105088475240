.terms-and-condition-view-wrapper{
  height: 50vh;
}

.terms-and-conditions-top-wrapper{
  height: 100vh;
  background-color: #f7f7f7;
}

.terms-and-conditions-download{
  cursor: pointer;
  left: -3px;
}

.terms-and-conditions--main-wrapper{

  .terms-and-conditions-card-image{
    height: 8vh;
  }

  .hr-col{
    height: 3vh;
  }

  .terms-and-condition-agree{
    height: 7vh;
  }

  .terms-and-conditions-download-next{
    height: 6vh;
  }

  hr{
    line-height: 1.6em !important;

  }

  .terms-and-conditions-card{
    height: 85vh !important;
    color: #757575 !important;
    box-shadow: 0 0 10px rgba(117, 117, 117, 0.65) !important;
    border: 0.1px solid #757575 !important;
    border-radius: 10px !important;
  }

  .terms-and-conditions-effective-col{
    height: 4vh;
  }

  .terms-and-conditions-next-button{
    width: 95%;
    font-size: 1.2em;
    border-radius: 10px;
  }

  .terms-and-conditions-title{
    font-size: 1.5em;
    color: #212121;
    height: 4vh;
  }

  .conditions-download-pdf{
    font-size: 1em;
  }

  .terms-and-conditions-next-button-wrapper{
    left:4%
  }

  .terms-and-conditions-effective{
    font-size: 1em;
  }

  .terms-and-conditions-para-view{
    width: 90%;
    height: 35vh;
    ol{
      padding-left: 30px !important;
    }
    .terms-and-conditions-para-view-para{
      font-size: 1em;
    }
  }

  .btn-purple{
    background-color: #0E6EFF;
    color: white;
    &:hover{
      background-color: #0D47A1 !important
    }
  }
  .beforeClick{
    color: #212121;
    font-size: 1em;
    span{
      padding-left: 0;
    }
    input[type="checkbox"]{
      transform: scale(1.5);
      -moz-outline-radius: 3px;
      outline:1px solid #007bff;
      outline-offset: -2px;
      margin-left: -6px;
    }

  }
  .afterClick{
    span{
      padding-left: 0;
    }
    color: #212121;
    font-size: 1em;
    input[type="checkbox"]{
      transform: scale(1.3);
      -moz-outline-radius: 3px;
      margin-left: -6px;
    }
  }
}

@media (max-width: 575.98px) {
  .terms-and-conditions--main-wrapper{
    font-size: 0.9rem !important;
    .terms-and-conditions-next-button-wrapper{
      left:0 !important;
    }
    .terms-and-conditions-para-view {
      height: 30vh;
      width: 100%;
    }
  }
  .terms-and-conditions-card-image{
    height: 9vh !important;
  }
  .terms-and-conditions-next{
    text-align: center !important;
  }
  .terms-and-conditions-download{
    text-align: center !important;
  }

  .beforeClick{

    input[type="checkbox"]{
      margin-left: 0 !important;
    }

  }
  .afterClick{
    input[type="checkbox"]{
      margin-left: 0 !important;
    }
  }

}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #0288D1;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
  width: 100%;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px
}

.thumbs {
  display: inline-flex !important;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 75px;
  height: auto;
  padding: 4px;
  box-sizing: border-box;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.remove-passport {
  position: absolute;
  cursor: pointer;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #2d434e;
  opacity: .6;
  margin-left: 2px;
  margin-top: 2px;
  padding: 0 5px 3px 6px;
  font-size: .7em;
  color: #ffffff;
}
.remove-passport:hover {
  opacity: 1;
  font-weight: 500;
}

.preview {
  display: flex !important;
  padding-top: 10px;
  justify-content: center;

  img {
    max-width: 180px;
    height: auto;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 576px) {
  .dropzone {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .dropzone {
    width: 100%;
  }
}
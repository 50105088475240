.auth-page {

}

.nav-styles {
  margin-top: 20px;

  .auth-nav-items {
    .nav-item a {
      margin-left: 30px;
      text-decoration: none;
      text-transform: capitalize !important;

      i {
        margin-right: 2px;
      }
    }

    .nav-item a:hover {
      color: #dddddd !important;
    }

    .active {
      text-decoration: underline solid #FFFFFF;
    }
  }
}

.full-page {
  position: relative;
  height: 100vh;
}

.full-page-inner, .full-page-inner::after {
  position: absolute;
  display: block;
  z-index: 2;
  height: 100%;
  width: 100%;
  //background-color: rgba(10, 62, 43, .7);
  background-color: rgba(44, 62, 80, .8);
  top: 0;
  left: 0;
}

.page-bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-image: url("../img/auth/boat01.jpg");
  background-size: cover;
  background-position: 50%;
}

.login-dialog {
  margin: 10% auto;
  border-radius: 5px;
  height: 100%;
  width: 90%;
  max-width: 360px;
  background-color: #ffffff;
  text-align: center;
  padding: 50px 20px;
}

.login-form {
  margin-top: 40px;

  .input-group {
    //border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: transparent;
  }

  .input-group-prepend {
    background-color: transparent;
    padding: 10px;
    border: 1px solid #dddddd;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    i {
      font-size: 18px;
      color: #999999;
    }
  }

  .form-control {
    background-color: transparent;
    //border: none !important;
    height: auto;
  }

  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0 !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }

  .login {
    margin: 25px 0 50px 0;
  }

  .divider {
    margin-bottom: 5px;
  }
}

.developer {
  padding-top: 3px;
  font-size: 10px;
  text-align: right;
  color: #999999;

  a {
    color: #999999;
  }
}

.error-container {
  min-height: 25px;
}

.login-error {
  font-weight: 500;
  color: #af0606;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .login-dialog {
    margin: 100px auto;
  }
  .navbar-collapse {
    background-color: #f1f1f1;
    border-bottom: 1px solid #16a085;

    a {
      color: #3C4858 !important;
    }
  }
  .full-page-inner {
    height: 120%;
  }
  .page-bg {
    height: 120%;
  }
  .nav-styles {
    margin-top: 0;
    .auth-nav-items {
      .nav-item a {
        display: none;
      }
    }
  }
  .navbar-toggler {
    display: none;
  }
  .navbar-toggler-icon {
    display: none !important;
  }
}
@media print {
  .print-only{
    display: block;
  }
  .pos-actions, .pos-header, .bill-tabs-container, .table-num {
    display: none;
    width: 0 !important;
  }
  .left-container {
    width: 90% !important;
    max-width: 90% !important;
    flex: 1 !important;
    //margin: 250px auto;
  }
  .items-container {
    height: auto !important;
    max-height: inherit !important;
  }
  .item-data {
    font-size: 16px;
    margin-top: 20px !important;
    margin-bottom: 20px;
    flex: none !important;
  }
  .item-data:nth-child(5) {
    background-color: #5e5e5e !important;
  }
  //.item-data:nth-child(13) {
  //  margin-top: 250px !important;
  //}
  html, body {
    display: block !important;
    height: 100vh !important;
    //height: auto !important;
    overflow: visible !important;
    //margin: 250px auto;
  }
  @page  {
    margin: 50px 0 150px auto;
  }
  body { margin: 1cm; }
  * {
    overflow: visible !important;
    position: relative;
  }
  .invoice-num{
    display: block;
    text-align: right;
  }

  .pos-container{
    margin-top: -60px !important;

  }

  .amount-col, .amount-th{
    display: block;
  }
  .amount-col{
    text-align: right;
  }
  .item-list-header{
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    font-weight: bold;
    .border-right{
      border-right: none !important;
    }
  }
  .item-data{
    padding-top: 0 !important;
    border-bottom: 1px solid #dddddd;
  }
  .table-bottom-hr{
    border-top: 1px solid #000000;
  }
  .print-mt-40{
    //margin-top: 50px !important;
  }

  //print sizes
  .col-print-1, .col-print-2, .col-print-3, .col-print-4, .col-print-5, .col-print-6, .col-print-7, .col-print-8, .col-print-9, .col-print-10, .col-print-11, .col-print-12 {
    float: left;
  }
  .col-print-12 {
    width: 100%;
  }
  .col-print-11 {
    width: 91.66666667%;
  }
  .col-print-10 {
    width: 83.33333333%;
  }
  .col-print-9 {
    width: 75%;
  }
  .col-print-8 {
    width: 66.66666667%;
  }
  .col-print-7 {
    width: 58.33333333%;
  }
  .col-print-6 {
    width: 50% !important;
  }
  .col-print-5 {
    width: 41.66666667%;
  }
  .col-print-4 {
    width: 33.33333333% !important;
  }
  .col-print-3 {
    width: 25%;
  }
  .col-print-2 {
    width: 16.66666667%;
  }
  .col-print-1 {
    width: 8.33333333%;
  }
  //end print sizes


}
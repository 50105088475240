%card-stats{
  hr{
    margin: 5px 15px;
  }
}


.card-stats{
  .card-body{
        padding: 15px 15px 0px;

        .numbers{
          text-align: right;
          font-size: 2em;
            p{
                margin-bottom: 0;
            }
            .card-category {
              color: $dark-gray;
              font-size: 16px;
              line-height: 1.4em;
            }
        }
    }
    .card-footer{
        padding: 0px 15px 15px;

        .stats{
          color: $dark-gray;
        }

        hr{
          margin-top: 10px;
          margin-bottom: 15px;
        }
    }
    .icon-big {
        font-size: 3em;
        min-height: 64px;

        i{
            line-height: 59px;
        }
    }

}

.page-nav.active .card{
  background-color: #FAFAFA;
  //background-color: #ecf0f1;
  //border: 1px solid #0c2646;
  .active-circle{
    display: block;
  }
}
a:hover {
  text-decoration: none !important;
}
.active-circle{
  color: #00bbff !important;
  float: right;
  margin: 7px 0 0 !important;
  display: none;
}
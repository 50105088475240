.pos-header {
  width: 100%;
  padding: 15px;
  height: 50px;
  background-color: #2c3e50;
  position: absolute;
  top: 0;
  //z-index: 1;
  color: #f4f3ef;
  p {
    color: #78909C;
  }
}

.single-invoice-header{
  img{
    width: 5% !important;
  }
  span{
    font-size: 1.7em !important;
  }
}

.single-invoice-addresses{
  span{
    font-size: 0.9em !important;
  }
}

.pos-actions {
  display: flex;
  justify-content: flex-start;
  height: 100vh;
  //background-color: #00bbff;
  background-color: #ecf0f1;
  box-shadow: -2px 0px 2px #dddddd;
  .card {
    margin-bottom: 0;
  }
  .pos-h {
    border-radius: 0;
    height: 50px;
    .icon-big {
      font-size: 2em;
      cursor: pointer;
    }
    .card-body {
      padding: 0 15px 0;
    }
  }
  .buttons-container {
    flex-grow: 1;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .pos-footer {
    border-radius: 0;
    height: 163px;
    box-shadow: 2px 0px 4px 0px #5e5e5e;
    //flex-grow: 1;
  }

  //.dis-none {
  //  display: none !important;
  //}
  .button-row {
    justify-content: center;
    margin-bottom: 15px;
  }
  .pos-button {
    height: 90px;
    //width: 100px;
    background-color: #0c2646;
    border-radius: 3px;
    box-shadow: 1px 1px 2px #2c2c2c;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 7px;
    justify-content: space-evenly;
    cursor: pointer;
    .nc-icon, .fa, .fas {
      font-size: 2em;
    }
    .far {
      font-size: 2.2em;
    }
    .ic-white {
      color: #f4f3ef !important;
    }
    p {
      color: #f1f1f1;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .text-big {
      font-size: 2em;
    }
  }
  .button-c1 {
    //background-color: #009688;
    //background-color: #326982;
    //background-color: #00796B;
    //background-color: #038387;
    //background-color: #245d68;
    background-color: #1f6977;
    //background-color: #1c7261;
  }
  .button-c2 {
    background-color: #112947;
  }
  .button-warning {
    //background-color: #FB8C00;
    background-color: #F9A825;
  }
  .button-danger {
    background-color: #C62828;
  }
  .button-inactive {
    background-color: #34495e;
  }
  .button-go {
    background-color: #00796B;
  }
}

.left-container {
  padding-top: 50px;

}

.values-container {
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 2px 2px #dddddd;
}

.value-state {
  padding: 5px 15px;
  .on-state {
    display: flex;
    justify-content: center;
    font-weight: 200;
  }
  .icon-big {
    font-size: 1.5em;
  }
  label {
    font-size: 1.1em;
    margin-left: 10px;
  }
}

.value-state .active {
  color: #038387;
  font-weight: 500;
  .icon-big {
    color: #038387 !important;
  }
}

.bill-tabs-container {
  border-bottom: 1px solid #ecf0f1;
}

.mdc-tab {
  height: 32px !important;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #263238 !important;
}

.mdc-tab-indicator > .mdc-tab-indicator__content--underline {
  background-color: #263238 !important;
}

.customer-details {
  display: flex;
  align-items: center;
  i {
    font-size: 2em;
  }
  p {
    margin: 5px 15px;
    font-weight: 600;
  }
  .selected-customer {
    text-transform: uppercase;
  }
  .loyalty {
    align-self: flex-end;
  }
}

.table-num {
  float: right;
}

.item-list-header {
  background-color: #dddddd;
  border-radius: 5px;
  //box-shadow: 1px 1px 1px #999999;
  font-size: 13px;
}

//.item-list-header > div{
//  border-right-color: #eeeeee !important;
//}
.item-data {
  font-size: 12px;
  border-bottom: 1px dashed #ecf0f1;
  cursor: pointer;
}

.item-data:last-child {
  border-bottom: none;
}

.amount-col {
  display: none;
}

.item-data > div:first-child {
  text-align: center;
}

.items-container {
  width: 100%;
  overflow-y: auto;
  .edit-product-price {
    width: 100%;
  }
  .item-editor {
    margin-top: 5px;
  }
  .form-group > label {
    margin-bottom: 0;
  }
  .form-group > input {
    padding: 5px 8px;
    text-align: right;
  }
  .editor-open {
    background-color: #ecf0f1;
    border: 1px solid #3b5998;
    border-radius: 5px;
  }
  .item-editor-buttons {
    display: flex;
    justify-content: space-between;
  }
  .remove-item {
    margin-top: 10px;
    padding: 11px;
    border: 1px solid #EF8157;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    i {
      font-size: 1.5em;

    }
  }
  .remove-item:hover {
    background-color: #EF8157;
    border: 1px solid #C62828;
    i {
      color: #C62828 !important;
    }
  }
  .close-editor {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #6c757d;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    i {
      font-size: 1.5em;

    }
  }
  .close-editor:hover {
    background-color: #b8d8d8;
  }
}

.items-hr {
  margin-top: 0;
}

.text-max {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bill-summary-row {
  display: flex;
  justify-content: space-between;
  p {
    font-size: .9em;
    margin-bottom: 10px;
    color: #000000;
  }
}

.total-row {
  font-size: 2em;
  margin-bottom: 10px;
  p {
    color: #AB003C;
  }
  .small-cents {
    font-size: .5em;
  }
}

.categories {
  //display: flex;
  //flex-wrap: nowrap;
  height: 95px;
  overflow-y: auto;
  padding-right: 50px;

}

.category-button {
  height: 80px;
  width: 120px;
  min-width: 120px;
  margin-right: 15px;
  background-color: #0c2646;
  border-radius: 3px;
  box-shadow: 1px 1px 2px #2c2c2c;
  display: flex;
  text-transform: capitalize;
  text-align: center;
  flex-direction: column;
  padding: 7px;
  justify-content: space-evenly;
  cursor: pointer;
  color: #f1f1f1;
  p {
    margin-bottom: 0;
  }
}

.categories .active {
  .category-button {
    background-color: #00838F;
    box-shadow: 1px 1px 2px #cccccc;
    font-weight: 600;
    font-size: 1.1em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  //justify-content: flex-end;
  align-content: flex-start;
  height: 320px;
  overflow-x: auto;
}

.product-button {
  width: 170px;
  height: 110px;
  border: 1px solid #3b5998;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  //flex-direction: column;
  text-align: center;
  //justify-content: space-evenly;
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  p {
    margin-bottom: 0
  }
  .name-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
  }
  .product-name {
    //font-size: 1.5em;
    //margin-top: 15px;
    padding: 0 5px;
  }
  .price {
    color: #ffffff;
  }
  .product-circle {
    //margin-top: 22px;
    //float: right;
    color: #00bbff !important;
    //align-self: flex-end;
    //margin-right: 10px;
  }
  .bottom-row {
    //display: flex;
    align-self: flex-end;
  }
  .bottom-row > div {
    display: flex;
    padding: 2px 0;

    background-color: #607D8B;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.center-header {
  padding: 0 !important;
  h6 {
    margin-bottom: 5px;
    //font-weight: 500;
  }
}

.customer-header {
  border-bottom: 1px dashed #16A085 !important;
  h6 {
    color: #16A085 !important;
  }
}

.action-center {
  padding-top: 0 !important;

  .btn-customer-add {
    margin-top: 0;
    height: 45px;
    width: 45px;
    padding: 0;
    border-radius: 100px;
    background-color: #EB626A !important;
    i {
      font-size: 2em;
    }
  }
  .btn-customer-add:hover {
    background-color: #EB626A !important;
    box-shadow: 0 1px 1px #2c2c2c !important;
  }
  .search-customer-group {
    padding-top: 3px;
  }
  .action-hr {
    border-bottom: 1px dotted #dddddd !important;
  }
  .customer-center {
    max-height: 390px;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  .phone-numbmer {
    letter-spacing: .1em;
  }
}

.selected-customer {
  .card-body {
    padding: 0;
  }
  .customer-photo-container {
    background-color: #038387;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .customer-icon {
    font-size: 3.2em;
    position: relative;
    margin-right: -37px;
    margin-top: 16px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 25px;
  }
  .customer-name {
    font-size: 1.5em;
  }
  .tel {
    font-size: .9em;
    font-weight: 600;
  }
  .customer-status {
    display: flex;
    align-items: center;
  }
  .message {
    padding-left: 10px;
    font-size: .9em;
  }
  p {
    margin-bottom: 0;
  }
}

.customer-credit {
  .card-header {
    padding-top: 5px;
    p {
      margin-bottom: 5px;
    }
  }
  hr {
    margin-top: 0;
  }
  input {
    max-width: 130px;
  }
}

.due-invoices {
  padding-top: 5px !important;
  height: 144px;
  overflow-y: auto;
  .due-invoice-row {
    font-size: .9em;
    color: #af0606;
    margin-bottom: 7px;
  }
  hr {
    margin-top: 10px;
  }
  .aging {
    font-size: 1.1em;
  }
}

.due-summary {
  color: #b71c1c;
  font-weight: 600;
}

.enable-feature {
  margin: 150px auto;
  text-align: center;
  font-weight: bold;
  color: #1F77D0;
  font-size: 1.2em;
}

.invoice-num {
  //print only
  display: none;
  font-size: 1.1em;
}

.discount-group {
  padding: 30px;
  .discount {
    height: 90px;
    max-width: 150px;
    display: inline;
    font-size: 4em;
    text-align: right;

  }
  .percentage{
    display: inline;
    font-size: 3.5em;
    color: #000000;
    font-weight: bolder;
    margin: 0px 15px;
  }
}
.back-cont{
  margin-top: 100px;
}
.print-only{
  display: none;
}
.main-category-name{
  font-size: 20px !important;
  font-weight: 600;
}
.main-category-upload-button{
  border-radius: 20px !important;
  color: white !important;

}

.sub-category-main-category-name{font-size: 1.1em;
  font-weight: 600;}

.create-main-category-line{
color: blue;

}

.create-main-category-line-sub{
  padding: 10px;

  &:hover{
    background-color: #747d8c !important;
    border-radius: 5px;
    color: white;
  }
}
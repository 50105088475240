// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.wrapper-home {
  width: 100% var(--scrollbarWidth);
  height: calc(100vh - 70px);
}

.logo-home {
  position: relative;
  margin: 20px 0 40px 0;

  img {
    width: auto;
    max-height: 90px;
  }
}

.customer-img {
  position: relative;
  //vertical-align: middle;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
}

.home-btn {
  .signIn-btn {
    position: relative;
    width: 180px;
    border-radius: 10px;
    background-color: #0E6EFF;
    border: none;
    font-size: 1.1em;
    font-weight: 600;
    padding: 11px 22px;
    margin-right: 5.5rem;
    margin-top: 3.6rem;

    &:hover {
      color: #0D47A1;
      background: none;
    }

    &:focus {
      background-color: #FAFAFA;
      border-color: #FAFAFA;
    }
  }

  .pricing-btn {
    position: relative;
    width: 250px;
    border-radius: 10px;
    background-color: #16a085;
    border: 2px solid #16a085 !important;
    color: #ffffff;
    font-size: 1.1em;
    font-weight: 600;
    padding: 11px 22px;
    margin-top: 0.5rem;

    &:hover {
      color: #ffffff;
      background-color: #004D40 !important;
    }

    &:focus {
      background-color: #FAFAFA;
      border-color: #FAFAFA;
    }
  }

  .demo-btn {
    position: relative;
    width: 250px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid #263238 !important;
    color: #263238;
    font-size: 1.1em;
    font-weight: 600;
    padding: 11px 22px;
    margin-top: 0.5rem;

    &:hover {
      color: #ffffff;
      background-color: #263238 !important;
    }

    &:focus {
      background-color: #FAFAFA;
      border-color: #FAFAFA;
    }
  }
}

.home-title {
  position: relative;
  //margin-top: 3rem;

  h2 {
    font-size: 3.5em;
    color: #e8505b;
    font-weight: 600;
  }
}

.home-sub-text {
  position: relative;
  margin-top: 0.5rem;

  p {
    color: #006266;
  }
}

.bg-lines {
  width: 100%;
  height: auto;
  background-image: url("../../img/line.png"),
  url("../../img/line.png"),
  url("../../img/line.png"),
  url("../../img/line.png");
  background-repeat: no-repeat;
  background-position: top 70px left 20px, right 126px, left 599px, right 88px center, left 224px center;
}

.pricing-text {
  position: relative;
  a {
    text-decoration: underline;
    color: #424242;
    font-size: 1.1em;
  }
  a:hover {
    text-decoration: underline !important;
    color: #0E6EFF;
  }
}

@media (max-width: 768px) {
  .wrapper-home {
    height: auto;
  }
  .logo-home {
    margin-left: 1rem;
  }
  .home-btn {
    .signIn-btn {
      font-size: 1em;
      margin-top: 1.6rem;
      width: 10rem;
      margin-right: 1.5rem !important;
    }
    .pricing-btn {
      font-size: 1em;
    }
    .demo-btn {
      font-size: 1em;
    }
  }
  .logo-home {
    img {
      max-height: 60px;
    }
  }
  .home-title {
    margin-top: 0.5rem;

    h2 {
      font-size: 2rem;
    }
  }
  .pricing-btn {
    width: 100% !important;
  }
  .demo-btn {
    width: 100% !important;
  }
  .bg-lines {
    background-position: top 1px left 1px, right 126px, top 1px left 290px, right 88px center, left 124px center;
  }

}
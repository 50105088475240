.editor-options {
  display: inline-block;
  float: right;
  a{
    color: black;
    &:hover{
      color: white;
    }
  }
  .dropdown-toggle {
    //background: transparent;
    border: none;
    color: #919aa3;
    height: 25px;
    padding: 4px 12px;
    i {
      color: #919aa3;
      font-size: 1.3em;
    }
    &:focus{
      color: white !important;
    }
  }
  //.show > button {
  //  background-color: $options-button-active-bg !important;
  //  border-radius: 3px;
  //  i {
  //    color: $options-button-active-color;
  //  }
  //}
  ul {
    padding: 15px 0;
  }
  li {
    font-size: .8em;
    font-weight: 600;
  }
  li:active, li:focus {
    i {
      color: white;
    }
    a{
      color: white !important;

    }
  }



  .dropdown-item {
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .btn-primary.dropdown-toggle {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .btn-primary.dropdown-toggle:focus{
    background-color: #57606f !important;
    i{
      color: white;
    }

    border: none;
    box-shadow: none;
  }
  .btn-primary.dropdown-toggle:active{
    background-color: #57606f !important;
    i{
      color: white;
    }
    border: none;
    box-shadow: none;
  }
  //.btn-primary .dropdown-toggle:focus, .btn-primary .dropdown-toggle:active {
  //  box-shadow: none;
  //  //background: transparent !important;
  //  border: none;
  //  color: #57606f !important;;
  //}

  .btn-primary.dropdown-toggle:hover{
    background-color: #57606f !important;
    i{
      color: white;
    }

  }

  .btn-primary.dropdown-toggle-clicked{
    background-color: #57606f !important;
  }


}

.options-main-wrapper{
  display: inline;
  position: relative;
  top: -8px;
}

.dropdown-menu .dropdown-item:first-child:hover {
  border-top-left-radius: 0 !important;
  color: white !important;
  border-top-right-radius: 0 !important;
}

.dropdown-menu .dropdown-item:last-child:hover {
  color: white !important;
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.dropdown-item a:hover{
  color: white;
}
.md-body {
  display: flex;
  justify-content: center;
  p {
    font-size: 1rem;
  }
}

.product-del-btn {
  margin-left: 20px !important;
  border-radius: 20px !important;
  border: none !important;
  background-color: #ef5350 !important;
}

.product-del-btn:hover {
  background-color: #e57373 !important;
}

.product-cancel-btn {
  border-radius: 20px !important;
  border: none !important;
  background-color: #9E9E9E !important;
}

.product-cancel-btn:hover {
  background-color: #757575 !important;
}

.del-icon-info {
  .icon-info {
    display: flex;
    padding-top: 25px;
    font-size: 4rem;
    color: #F9A825;
    justify-content: center;
  }
}
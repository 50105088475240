// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.spenceHub-logo {
  position: relative;
  max-width: 90px;
  img {
    vertical-align: middle;
  }
  z-index: 4;
  margin: 0.2em 0 0.4em 28px;
}

.btm-hr {
  border-bottom: 1px solid #0c2646;
}
